import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '..';

import css from './SectionCities.module.css';

import bcnImage from './images/pic1.png'; //pic_Barcelona.jpg';
import madImage from './images/pic2.png'; //pic_Madrid.jpeJmg';
import valImage from './images/pic3.png'; // Tenemos que cambiar el fichero imagen de Valencia si queremos añadir la localidad Valencia
import { shuffle } from 'lodash';

class cityImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(cityImage);

const cityLink = (name, image, searchQuery) => {
  const nameText = <span className={css.cityName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.city}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.cityImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionCities.listingsInCity"
          values={{ city: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionCities = props => {
  const { rootClassName, className } = props;

  const [links, setLinks] = useState([
    cityLink(
            'Gimnasio',
            nyImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
          )
        ,
    cityLink(
            'Madrid',
            madImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
          )
        ,
    cityLink(
            'Barcelona',
            bcnImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
          )
        
])
  


  useEffect(() => {
    const today = new Date();
    const date = today.getDate();
    const isEven = date % 2 === 0;
    const isDivByThree = date % 3 === 0;
    if(isEven){
      setLinks([
        cityLink(
          'Barcelona',
          bcnImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
        ),
        cityLink(
                'Gimnasio',
                nyImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
              )
            ,
        cityLink(
                'Madrid',
                madImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
              )
            
    ])
    }


    if(isDivByThree){
      setLinks([
        cityLink(
          'Sala',
          bcnImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
        ),
        cityLink(
          'Cocina',
          madImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
        ),
        locationLink(
                'Gimnasio',
                nyImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
              )    
    ])
    }

  }, [])


  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <center>
        <FormattedMessage id="SectionCities.title" />
        </center>
      </div>
      <div className={css.cities}>
        {links.map(l => {


          return l
        })}
      </div>
    </div>
  );
};

SectionCities.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCities.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCities;
