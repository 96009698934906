import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
     
      <h3>
        <p>En cumplimiento de la Ley 34/2002 de Servicios de la Sociedad de la Información y de Comercio Electrónico de España, le informamos que esta página Web es propiedad de Jordi Muntada Gomez, en adelante Hours, con domicilio en C/ Sant Pere, 32, 1r 3a 08221 Terrassa BARCELONA, con CIF nº 45857256V.</p>
        <p>Para cualquier consulta o propuesta, contáctenos en info@hours.es o en el teléfono 626381615</p>
        <p>Esta página Web se rige por la normativa exclusivamente aplicable en España, quedando sometida a ella, tanto nacionales como extranjeros que utilicen esta Web.</p>
        <p>El acceso a nuestra página Web por parte del USUARIO es gratuito y está condicionado a la previa lectura y aceptación integra, expresa y sin reservas de las presentes CONDICIONES GENERALES DE USO vigentes en el momento del acceso, que rogamos lea detenidamente. El USUARIO en el momento que utiliza nuestro portal, sus contenidos o servicios, acepta y se somete expresamente a las condiciones generales de uso del mismo. Si el usuario no estuviere de acuerdo con las presentes condiciones de uso, deberá abstenerse de utilizar este portal y operar por medio del mismo.</p>
        <p>En cualquier momento podremos modificar la presentación y configuración de nuestra Web, ampliar o reducir servicios, e incluso suprimirla de la Red, así como los servicios y contenidos prestados, todo ello de forma unilateral y sin previo aviso.</p>
        <p>1. PROPIEDAD INTELECTUAL</p>
        <p>Todos los contenidos, textos, imágenes, y códigos fuente son propiedad de Hours, o de terceros a los que se han adquirido sus derechos de explotación, y están protegidos por los derechos de Propiedad Intelectual e Industrial.</p>
        <p>El usuario únicamente tiene derecho a un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa de Hours y/o el titular de los derechos para modificarlos, reproducirlos, explotarlos, distribuirlos o cualquier derecho perteneciente a su titular.</p>
        <p>El establecimiento de enlaces a nuestro portal no confiere ningún derecho sobre el mismo, y se autoriza exclusivamente a permitir el acceso a nuestra web, quedando prohibida en otras páginas la reproducción total o parcial de las imágenes y contenidos de nuestro portal. Asimismo, el simple hecho de establecer un enlace a nuestra página web no da derecho a otorgarse la categoría de colaborador o socio de Hours.</p>
        <p>Está absolutamente prohibida la imitación ya sea total o parcial de nuestro portal.</p>
        <p>2. CONDICIONES DE ACCESO</p>
        <p>El acceso a nuestra página Web es gratuito y no exige previa suscripción o registro. No obstante, Hours se reserva el derecho de ofrecer servicios que requieran el registro previo del usuario. En todo caso, estos servicios quedarán debidamente identificados en la Web, con fáciles indicaciones para su registro.</p>
        <p>El usuario debe acceder a nuestra página Web conforme a la buena fe, las normas de orden público, a las presentes Condiciones Generales de uso. El acceso a nuestro sitio Web se realiza bajo la propia y exclusiva responsabilidad del usuario, que responderá en todo caso de los daños y perjuicios que pueda causar a terceros o a nosotros mismos.</p>
        <p>El usuario tiene expresamente prohibido la utilización y obtención de los servicios y contenidos ofrecidos en la presente página web, por procedimientos distintos a los estipulados en las presentes condiciones de uso, y en su caso en las condiciones particulares que regulen la adquisición de determinados servicios.</p>
        <p>Teniendo en cuenta la imposibilidad de control respecto a la información, contenidos y servicios que contengan otras websites a los que se pueda acceder a través de los enlaces que nuestra página web pone a su disposición, le comunicamos que Hours, queda eximida de cualquier responsabilidad por los daños y perjuicios de toda clase que pudiesen derivar por la utilización de esas páginas web ajenas a nuestra empresa por parte del usuario.</p>
        <p>Hours se reserva el derecho de ejercitar las acciones legales oportunas contra aquellos USUARIOS que vulneren las presentes condiciones generales de uso, aceptando el USUARIO que la no iniciación de estas acciones no constituye una renuncia formal a los mismas, permaneciendo éstas vigentes hasta los plazos de prescripción de las infracciones.</p>
        <p>3. PROTECCIÓN DE DATOS PERSONALES</p>
        <p>La confidencialidad, el secreto profesional y la seguridad son valores primordiales de Hours, que asume el compromiso de garantizar la privacidad del usuario/visitante en todo momento, y en todas las interacciones con aquel. Hours asume también el compromiso de no recabar información innecesaria sobre el Usuario. Asimismo, nos comprometemos a tratar con extrema diligencia la información personal que el Usuario pueda facilitar a través de nuestra web.</p>
        <p>De conformidad con la Ley Orgánica 15/1999 de Protección de Datos de Carácter Personal (LOPD), y Real Decreto 1720/2007 de desarrollo de la LOPD, le informamos que la utilización de determinados servicios en nuestra página web requiere que nos facilite determinados datos personales a través de formularios de registro o mediante el envío de mensajes de correo electrónico, y que éstos serán objeto de tratamiento e incorporados a los ficheros de Hours titular y responsable del mismo. El envío referido de los datos personales constituye el consentimiento expreso al tratamiento de los mismos, si bien de carácter revocable y sin efectos retroactivos.</p>
        <p>Asimismo le informamos que todos nuestros ficheros se encuentran legalmente inscritos en el Registro General de Datos Personales de la Agencia Española de Protección de Datos, así como que para salvaguardar la seguridad de sus datos personales se aplicarán las medidas de seguridad técnicas y organizativas exigidas en el Real Decreto 1720/2007 que regula el reglamento de medidas de seguridad de los ficheros que contengan datos de carácter personal.</p>
        <p>Uso de sus datos personales</p>
        <p>Los datos que le solicitamos son los adecuados, pertinentes y estrictamente necesarios para la finalidad por la que se recogen, y en ningún caso está obligado a facilitárnoslos. Asimismo, nos certifica que todos los datos que nos facilita son ciertos, veraces y pertinentes para la finalidad por la que se los solicitamos.</p>
        <p>Nuestra página Web, recoge sus datos personales mediante la recepción de diversos formularios y a través del correo electrónico dónde puede solicitarnos cualquier tipo de información, aclaración o duda, e incluso remitirnos su currículum vitae. El envío de los mismos implica su autorización a incorporarlos a nuestros ficheros correspondientes, si Hours lo considera conveniente, y éstos estarán regulados por las presentes políticas de privacidad. Sus datos serán incorporados a nuestros ficheros de tratamiento de datos.</p>
        <p>Hours se reserva el derecho de decidir la incorporación o no de sus datos personales a sus ficheros.</p>
        <p>Derechos de acceso, rectificación, cancelación de sus datos personales</p>
        <p>En cualquier momento puede oponerse a nuestros envíos comerciales, así como ejercer sus derechos de acceso, rectificación y cancelación en los términos establecidos legalmente, pudiendo en la dirección y datos indicados en el inicio del presente Aviso Legal, aportándonos copia de su DNI.<br />Comunicaciones de datos</p>
        <p>Hours le informa que sus datos son tratados confidencialmente y son utilizados exclusivamente de manera interna y para las finalidades indicadas. Por lo tanto, no cedemos ni comunicamos a ningún tercero tus datos, excepto en los casos legalmente previstos, o que el USUARIO nos lo autorice expresamente.</p>
        <p>Seguridad de sus datos personales</p>
        <p>Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que Hours ha adoptando todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados de su alteración, pérdida, y tratamientos o accesos no autorizados, tal como exige el Real Decreto 1720/2007 regula el reglamento de medidas de seguridad de los ficheros que contengan datos de carácter personal.</p>
        <p>Actualización de sus datos</p>
        <p>Es importante que para que podamos mantener sus datos personales actualizados, nos informe siempre que haya habido alguna modificación en ellos, en caso contrario, no respondemos de la veracidad de los mismos.</p>
        <p>Consideramos que si no cancela sus datos personales expresamente de nuestros ficheros, continúa interesado en seguir incorporado a los mismos hasta que Hours lo considere oportuno y mientras sea adecuado a la finalidad por la que se obtuvieron.</p>
        <p>Hours no se hace responsable de la política de privacidad respecto a los datos personales que pueda facilitar a terceros por medio de los enlaces disponibles en nuestra página web.</p>
        <p>Hours puede modificar las presentes políticas de privacidad para adaptarlas a las modificaciones que se produzcan en nuestra web, así como modificaciones legislativas o jurisprudenciales sobre datos personales que vayan apareciendo, por lo que exige su lectura, cada vez que nos facilite sus datos a través de esta Web.</p>
        <p>4. RESPONSABILIDADES</p>
        <p>Al poner a disposición del usuario esta página Web queremos ofrecerle toda una serie de contenidos y servicios de calidad, utilizando la máxima diligencia en la prestación de los mismos así como en los medios tecnológicos utilizados. No obstante, no responderemos de la presencia de virus y otros elementos que de algún modo puedan dañar el sistema informático del usuario.</p>
        <p>El USUARIO tiene prohibido cualquier tipo de acción sobre nuestro portal que origine una excesiva sobrecarga de funcionamiento a nuestros sistemas informáticos, así como la introducción de virus, o instalación de robots, o software que altere el normal funcionamiento de nuestra web, o en definitiva pueda causar daños a nuestros sistemas informáticos.</p>
        <p>El usuario asume toda la responsabilidad derivada del uso de nuestra página web, siendo el único responsable de todo efecto directo o indirecto que sobre la página web se derive, incluyendo, de forma enunciativa y no limitativa, todo resultado económico, técnico y/o jurídico adverso, así como la defraudación de las expectativas generadas por nuestro portal, obligándose el usuario a mantener indemne a Hours por cualesquiera reclamaciones derivadas, directa o indirectamente de tales hechos.</p>
        <p>Hours queda exonerado de responsabilidad por cualquier reclamación respecto a los derechos de propiedad intelectual de los artículos e imágenes publicadas en su portal, así como, no garantiza la exactitud, veracidad y vigencia de los contenidos de esta página web, ya sean propios, de terceros, o enlazables a otras websites, quedando totalmente exonerada de cualquier responsabilidad derivada del uso de la mismos.</p>
        <p>Hours queda exonerada de cualquier responsabilidad derivada de cualquier reclamación, incluido el pago de honorarios a abogados, por las demandas y reclamaciones originadas por terceros por el incumplimiento por parte del USUARIO de nuestras condiciones de uso, acceso y política de privacidad, o cualesquiera otra reclamación por el incumplimiento de la legislación vigente.</p>
        <p>El USUARIO reconoce que ha entendido toda la información respecto a las condiciones de uso de nuestro portal, y reconoce que son suficientes para la exclusión del error en las mismas, y por lo tanto, las acepta integra y expresamente.</p>
        <p>El USUARIO es plenamente consciente de que la mera navegación por la presente página web, así como la utilización de sus servicios, implica la aceptación de las presentes condiciones de uso.</p>
        <p>Todo lo referente a nuestra página web, se rige exclusivamente por las leyes españolas. En el caso de que se produzca cualquier tipo de discrepancia o diferencia entre las partes en relación con la interpretación y contenido de la presente página web, todas las partes se someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de Barcelona.</p>
        <p>5. VIGENCIA DE LAS CONDICIONES GENERALES DE USO</p>
        <p>Las presentes Condiciones Generales de Uso han sido modificadas con 01 de enero de 2013. En cualquier momento podemos proceder a su modificación: por favor, compruebe la fecha de emisión en cada ocasión en que se conecte a nuestra página Web y así tendrá la certeza de que no se ha producido modificación alguna que le afecte.</p>
        <p>Para cualquier cuestión respecto a las Condiciones de Uso de nuestra página web, puede ponerse en contacto con nosotros en los datos arriba indicados.</p>
      </h3>
   
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
