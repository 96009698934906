import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 22, 2022</p>

      <h2>INFORMACIÓN CORPORATIVA</h2>

      <p>
        En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información 
        y de comercio electrónico, Jordi Muntada Gómez le informa que es titular de los sitios web: www.hours.es, 
        de acuerdo con la exigencia del artículo 10 de la mencionada Ley, Jordi Muntada Gómez, notifica 
        los siguientes datos: el titular de esta página web es Jordi Muntada Gómez,  La dirección de correo 
        electrónico de contacto es: support@hours.es
      </p>

      <h2>POLÍTICA DE ENLACES Y EXENCIONES DE RESPONSABILIDAD</h2>
      <p>
      Jordi Muntada Gómez no se hace responsable del contenido de las páginas web a las que pueda acceder el usuario a través de los enlaces establecidos en sus sitios web y declara que en ningún caso examinará ni ejercitará ningún tipo de control sobre el contenido de otras páginas de la red.
      Asimismo, tampoco garantiza la disponibilidad técnica, exactitud, veracidad, validez o legalidad de páginas ajenas a su propiedad a las que se pueda acceder a través de los enlaces.
      Jordi Muntada Gómez declara que ha adoptado todas las medidas necesarias para evitar cualquier daño que pueda derivarse de la navegación por sus páginas web. En consecuencia, Jordi Muntada Gómez no se hace responsable, en ningún caso, de los eventuales daños que pueda sufrir el usuario a causa de la navegación por Internet.
      Jordi Muntada Gómez no se responsabiliza de los daños o perjuicios de cualquier tipo producidos en el Usuario que traigan causa de fallos o desconexiones en las redes de telecomunicaciones que produzcan la suspensión, cancelación, instalación o interrupción del servicio de la web durante la prestación del mismo o con carácter previo.
      El acceso a la web: www.hours.es no implica la obligación por parte de la empresa de controlar la ausencia de virus o cualquier otro elemento informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas informáticos dañinos.
      Jordi Muntada Gómez no se responsabiliza de los daños producidos en los equipos informáticos, documentos y/o ficheros de los Usuarios o de terceros durante la prestación del servicio en el Portal.

      </p>

      <h2>CORPORATE INFORMATION</h2>
      <p>
      En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio 
      electrónico, Jordi Muntada Gómez le informa que es titular de los sitios web: www.hours.es, de acuerdo con la 
      exigencia del artículo 10 de la mencionada Ley, Jordi Muntada Gómez, notifica los siguientes datos: el titular 
      de esta página web es Jordi Muntada Gómez,  La dirección de correo electrónico de contacto es: support@hours.es
      </p>

      <h2>POLÍTICA DE ENLACES Y EXENCIONES DE RESPONSABILIDAD</h2>
      <p>
      Jordi Muntada Gómez no se hace responsable del contenido de las páginas web a las que pueda acceder el usuario a través de los enlaces establecidos en sus sitios web y declara que en ningún caso examinará ni ejercitará ningún tipo de control sobre el contenido de otras páginas de la red.
      Asimismo, tampoco garantiza la disponibilidad técnica, exactitud, veracidad, validez o legalidad de páginas ajenas a su propiedad a las que se pueda acceder a través de los enlaces.
      Jordi Muntada Gómez declara que ha adoptado todas las medidas necesarias para evitar cualquier daño que pueda derivarse de la navegación por sus páginas web. En consecuencia, Jordi Muntada Gómez no se hace responsable, en ningún caso, de los eventuales daños que pueda sufrir el usuario a causa de la navegación por Internet.
      Jordi Muntada Gómez no se responsabiliza de los daños o perjuicios de cualquier tipo producidos en el Usuario que traigan causa de fallos o desconexiones en las redes de telecomunicaciones que produzcan la suspensión, cancelación, instalación o interrupción del servicio de la web durante la prestación del mismo o con carácter previo.
      El acceso a la web: www.hours.es no implica la obligación por parte de la empresa de controlar la ausencia de virus o cualquier otro elemento informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas informáticos dañinos.
      Jordi Muntada Gómez no se responsabiliza de los daños producidos en los equipos informáticos, documentos y/o ficheros de los Usuarios o de terceros durante la prestación del servicio en el Portal.

      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
