import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'Barcelona, España',
      bounds: new LatLngBounds(
        new LatLng(41.467943, 2.229555),
        new LatLng(41.317203, 2.052333)
      ),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Madrid, España',
      bounds: new LatLngBounds(
        new LatLng(40.643282, -3.518126),
        new LatLng(40.312064, -3.888956)
      ),
    },
  },
  {
    id: 'default-san-francisco',
    predictionPlace: {
      address: 'Valencia, España',
      bounds: new LatLngBounds(
        new LatLng(39.566194, -0.272536),
        new LatLng(39.278621, -0.432537)
      ),
    },
  },
];
export default defaultLocations;
