import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string, PropTypes } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import ChatIcon from '@mui/icons-material/Chat';
import { TopbarSearchForm, TopbarKeywordsSearchForm } from '../../forms';
import { post } from '../../util/api';



const sharetribeSdk = require('sharetribe-flex-sdk');


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    showTransparent,
    hideTopbarInput,
    setSearchType,
    searchType
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

      sdk.currentUser.show({
        include: ['stripeAccount', 'stripeCustomer']
      }).then(resp => {
        const hasCustomerId = resp.data.data.attributes.profile.privateData ? resp.data.data.attributes.profile.privateData.stripeCustomerId ? resp.data.data.attributes.profile.privateData.stripeCustomerId : null : null;
        const currentUser = resp.data.data;
        if(hasCustomerId){

                      const requestOptions = {
                        // method: 'POST',
                        // headers: {
                        //   'Content-Type': 'application/json'
                        // },
                        // body: JSON.stringify({
                                  stripeCustomerId: hasCustomerId
                                            // })
                                  }
              
                                                    
                   return post('/api/subscriptions', requestOptions).then(resp => {
                      const subscriptions = resp.subscriptions.data ? resp.subscriptions.data.length > 0 ? resp.subscriptions.data : [] : [];
                      const hasAnyActiveSubscription = subscriptions.find(s => {
                        return s.status = 'active';
                      });


                      if(!hasAnyActiveSubscription){
                          return sdk.currentUser.updateProfile({
                            publicData: {
                              subscriptions: []
                            },
                              protectedData: {
                                phoneNumber: ""
                               }
                          }).catch(e => {
                            //ignore error
                          })
                      }

                    }).catch(error => console.log(error));

        }
      }).catch(e => {
        //ignore error
      });

  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(
    rootClassName || css.root,
    !rootClassName && showTransparent && css.rootTransparent,
    className);

  const search = searchType === 'location' ?
    <TopbarSearchForm
      className={showTransparent ? css.searchLinkTransparent : css.searchLink}
      desktopInputRoot={showTransparent ? css.topbarSearchWithLeftPaddingTransparent : css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      showTransparent={showTransparent}
      hideTopbarInput={hideTopbarInput}
      setSearchType={setSearchType}
    />
    :
    <TopbarKeywordsSearchForm
      className={showTransparent ? css.searchLinkTransparent : css.searchLink}
      desktopInputRoot={showTransparent ? css.topbarSearchWithLeftPaddingTransparent : css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      showTransparent={showTransparent}
      hideTopbarInput={hideTopbarInput}
      setSearchType={setSearchType}
    />
  ;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={showTransparent ? css.transparentInboxLink : css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <ChatIcon className={css.chatIcon}/>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
      <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>

        {/* fav listings */}
        <MenuItem key="FavListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavListingsPage'))}
            name="FavListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavListingsPage" />
          </NamedLink>
        </MenuItem>


        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );


  const createListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched) ? null : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );

    const subscriptions =  
     isAuthenticatedOrJustHydrated && !(currentUserListingFetched) ? null : (
      <NamedLink       
      className={showTransparent ? css.transparentInboxLink : css.inboxLink}     
      name="StripeSubscriptionsPricesPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.subscriptions" />
        </span>
      </NamedLink>
    );

  return (
    <nav className={classes}>
        
       
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={showTransparent ? css.logoTransparent : css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          showTransparent={showTransparent}
        />
      </NamedLink>
      {/* {listingLink} */}
      {createListingLink}
      {search}
    
    
      {subscriptions}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
