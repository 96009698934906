import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput, FieldSelect } from '../../components';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
import css from './TopbarKeywordsSearchForm.module.css';

const identity = v => v;

class TopbarKeywordsSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
  }

  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            isMobile,
            handleSubmit,
            setSearchType
          } = formRenderProps;
          const classes = classNames(rootClassName, className);

          const handleOptionClick = (e) => {
            return setSearchType(e)
          }

          
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <Field
                name="keywords"
                render={({ input, meta }) => {
                  return (
                    <>
                      <div className={isMobile ? css.mobileIcon : css.desktopIcon}>
                          <IconHourGlass />
                       </div>

                      <input
                        className={
                          isMobile
                            ? css.mobileInput
                            : css.desktopInputRoot
                        }
                        {...input}
                        id="keyword-search"
                        ref={this.searchInput}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.placeholder',
                        })}
                        autoComplete="off"
                      />
                    
                    </>
        
                  );
                }}
              />
            {
              isMobile ?
              null 
              :
              <FieldSelect 
              id="searchType" 
              name="searchType" 
              className={css.selectField}
              onChange={handleOptionClick}
              >
                <option value="keywords">Palabras clave</option>
                <option value="location">Ubicación</option>
              </FieldSelect>
            }
        
            </Form>
          );
        }}
      />
    );
  }
}
const { func, string, bool } = PropTypes;

TopbarKeywordsSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarKeywordsSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarKeywordsSearchForm = injectIntl(TopbarKeywordsSearchFormComponent);

export default TopbarKeywordsSearchForm;
