import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import OwnListingLink from '../../components/OwnListingLink/OwnListingLink';
import {Button} from '../../components';
import icon1 from './icon1.png';
import icon2 from './icon2.png';
import icon3 from './icon3.png';
import css from './SectionVegetus.module.css';

const SectionVegetus = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched, history } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>

      {/* <div className={css.wrapper}> */}
        {/* <center>
          <div className={css.title}>
            <FormattedMessage id="SectionCarousel.title" />
          </div>
        </center> */}

        {/* <br/><br/>

        <div className={css.carouselWrapper}>
            <SectionCarousel history={history}/>
        </div> */}
      {/* </div> */}
      <center>
      <div className={css.title}>
        <FormattedMessage id="SectionVegetus.titleLineOne" />
        <br />
        <FormattedMessage id="SectionVegetus.titleLineTwo" />
      </div>
      </center>

      <div className={css.steps}>
        <div className={css.step}>

          <img src={icon1}  className={css.icon}/>

          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionVegetus.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionVegetus.part1Text" />
          </p>
        </div>

        <div className={css.step}>

        <img src={icon2}  className={css.icon}/>


          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionVegetus.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionVegetus.part2Text" />
          </p>
        </div>

        <div className={css.step}>

        <img src={icon3}  className={css.icon}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionVegetus.part3Title" />
          </h2>
          <p className={css.subtext2}>
            <FormattedMessage id="SectionVegetus.part3Text" />
          </p>
         
        </div>
      </div>
      <div className={css.createListingLink}>
      <center>
        <Button className={css.button} onClick={() => window.open('https://www.soyveget.us')}> Pedir catering</Button>
        {/* <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink> */}
        
        </center>
      </div>
    </div>
  );
};

SectionVegetus.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionVegetus.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionVegetus;
