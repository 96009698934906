import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import nyImage from './images/pic1.png';
import laImage from './images/pic2.png';
import sfImage from './images/pic3.png';
import { shuffle } from 'lodash';
class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const [links, setLinks] = useState([
  
    locationLink(
            'Gimnasio',
            nyImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
          )
        ,
    locationLink(
            'Cocina',
            laImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
          )
        ,
    locationLink(
            'Sala',
            sfImage,
            '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
          )
        
])
  


  useEffect(() => {
    const today = new Date();
    const date = today.getDate();
    const isEven = date % 2 === 0;
    const isDivByThree = date % 3 === 0;
    if(isEven){
      setLinks([
        locationLink(
          'Sala',
          sfImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
        ),
        locationLink(
                'Gimnasio',
                nyImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
              )
            ,
        locationLink(
                'Cocina',
                laImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
              )
            
    ])
    }


    if(isDivByThree){
      setLinks([
        locationLink(
          'Sala',
          sfImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=sala'
        ),
        locationLink(
          'Cocina',
          laImage,
          '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=cocina'
        ),
        locationLink(
                'Gimnasio',
                nyImage,
                '?address=España&bounds=45.41784954%2C3.83274117%2C33.33224649%2C-10.03217361&mapSearch=true&pub_category=gimnasio'
              )    
    ])
    }

  }, [])


  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <center>
        <FormattedMessage id="SectionLocations.title" />
        </center>
      </div>
      <div className={css.locations}>
        {links.map(l => {


          return l
        })}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
