/**
 * This is a wrapper component for different Layouts.
 * Topbar should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './LayoutWrapperTopbar.module.css';

const LayoutWrapperTopbar = props => {
  const { className, rootClassName, children, transparent } = props;
  const classes = classNames(
    rootClassName || (transparent ? css.rootTransparent : css.root),
    className);

  return <div className={classes}>{children}</div>;
};

LayoutWrapperTopbar.defaultProps = {
  className: null,
  rootClassName: null,
  transparent: false,
};

const { node, string, bool } = PropTypes;

LayoutWrapperTopbar.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  transparent: bool,
};

export default LayoutWrapperTopbar;
