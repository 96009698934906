/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React , { useState, useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { post } from '../../util/api';

const sharetribeSdk = require('sharetribe-flex-sdk');
const primiumSubscriptionID = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_ID;
const basicSubscriptionID = process.env.REACT_APP_BASIC_SUBSCRIPTION_ID;


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

const { UUID, LatLng } = sdkTypes;

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = currentTab => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, viewport } = props;
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentUser, setCurrentUser] = useState(false);

  
  useEffect(() => {
    sdk.currentUser.show({
      include: ['stripeAccount', 'stripeCustomer']
    }).then(resp => {
      const isConnected = resp.data.data.attributes.stripeConnected;
      const userEmail = resp.data.data.attributes.email;
      const userId = resp.data.data.id.uuid;
      const hasCustomerId = resp.data.data.attributes.profile.privateData ? resp.data.data.attributes.profile.privateData.stripeCustomerId ? resp.data.data.attributes.profile.privateData.stripeCustomerId : null : null;
      setIsStripeConnected(isConnected)
      setCurrentUser(resp.data.data)
      return [hasCustomerId, userEmail, userId]
    }).then(resp => {

      const stripeCustomerId = resp[0];
      const userEmail = resp[1];
      const userId = resp[2];

      if(stripeCustomerId){

        const requestOptions = {
          // method: 'POST',
          // headers: {
          //   'Content-Type': 'application/json'
          // },
          // body: JSON.stringify({
                     stripeCustomerId: stripeCustomerId
                              // })
                    }
  
        const fetchData = async () => {
          const {subscriptions} = await post('/api/subscriptions', requestOptions)
    
          setSubscriptions(subscriptions.data);
        }
        fetchData();

      }else{

        post('/api/create-customer', {
          // method: 'post',
          // headers: {
          //   'Content-Type': 'application/json',
          // },
          // body: JSON.stringify({
            email: userEmail,
            userId: userId
          // }),
        }).catch(error => console.log(error))

        setSubscriptions([]);
      }

     
    }).catch(e => console.log(e))

  }, []);

  
  const userProfile = currentUser ?  currentUser.attributes.profile : false;
  const subscription = userProfile ? userProfile.publicData ? (userProfile.publicData.subscriptions ? 
                                                (userProfile.publicData.subscriptions.includes(primiumSubscriptionID) ? 'premium' :
                                                (userProfile.publicData.subscriptions.includes(basicSubscriptionID) ? 'standard' : false)
                                                ) : false) : false : false;



  const hasSubscriptions = subscriptions.length !== 0;

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }



  const tabs = isStripeConnected ? 
  ( hasSubscriptions ?
  [
    {
      text: <FormattedMessage id={subscription ? "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitleSubscriptionOn" : "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle"} />,
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentTab === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
      selected: currentTab === 'StripeAccountSubscriptionsPage',
      id: 'StripeAccountSubscriptionsTab',
      linkProps: {
        name: 'StripeAccountSubscriptionsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.referral" />,
      selected: currentTab === 'ReferralPage',
      id: 'ReferralTab',
      linkProps: {
        name: 'ReferralPage',
      },
    },
  ] : 
  
  [
    {
      text: <FormattedMessage id={subscription ? "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitleSubscriptionOn" : "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle"} />,
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentTab === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
      selected: currentTab === 'StripeSubscriptionsPricesPage',
      id: 'StripeSubscriptionsPricesTab',
      linkProps: {
        name: 'StripeSubscriptionsPricesPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.referral" />,
      selected: currentTab === 'ReferralPage',
      id: 'ReferralTab',
      linkProps: {
        name: 'ReferralPage',
      },
    },
  ]
  
  ) : 
  [
    {
      text: <FormattedMessage id={subscription ? "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitleSubscriptionOn" : "LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle"} />,
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentTab === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
      selected: currentTab === 'StripeAccountSubscriptionsPage',
      id: 'StripeAccountSubscriptionsTab',
      linkProps: {
        name: 'StripeAccountSubscriptionsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.referral" />,
      selected: currentTab === 'ReferralPage',
      id: 'ReferralTab',
      linkProps: {
        name: 'ReferralPage',
      },
    },
  ] 
  ;

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
