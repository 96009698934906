import React, { Component, useEffect, useState } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';
import { NegociationModal } from './NegociationModal';

import css from './SendMessageForm.module.css';

const BLUR_TIMEOUT_MS = 100;

const IconSendMessage = () => {
  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={css.strokeMatter} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
    this.state = { offerSendOrAccepted: false };
    this.handleOfferSendOrAccepted = this.handleOfferSendOrAccepted.bind(this);
  }

  componentDidMount() {
    const messages = this.props.messages;
    const foundUserAccepted = messages.find(msg => {
      const isDecided = msg.attributes.content.includes("customMessageMarker!@#-accepted")
      return isDecided
      })

      const foundOfferSend = messages.find(msg => {
        const isSend = msg.attributes.content.includes("customMessageMarker!@#-");
        const isDeclined = msg.attributes.content.includes("customMessageMarker!@#-declined");
        return isSend && !isDeclined
      })

      const offerMessages = [];

      messages.forEach(msg => {
          if(msg.attributes.content.includes("customMessageMarker!@#")){
            offerMessages.push(msg.attributes.content)
          }
      })

   
      const declinedLastTime = offerMessages.length !== 0 ? offerMessages[0].includes("declined") : false;

      const disableSendOfferBtn = foundUserAccepted || foundOfferSend ? true : false;


        if(disableSendOfferBtn){
          this.setState({ offerSendOrAccepted: true });
        }

        if(declinedLastTime){
          this.setState({ offerSendOrAccepted: false });
        }
  }


  handleOfferSendOrAccepted(value) {
    {
      this.setState({ offerSendOrAccepted: value });
    }
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {

    const negociable = this.props.negociable;
    const isProvider = this.props.isProvider;

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            offerMessage,
            handleOfferMessage,
            onSendOffer,
            currentTransaction,
            currentListing,
            messages
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          const isTransactionCompleted = currentTransaction.attributes.lastTransition === "transition/complete-by-customer";
    


          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <FieldTextInput
                inputRootClass={css.textarea}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                placeholder={messagePlaceholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              <div className={css.submitContainer}>
                <div className={css.errorContainer}>
                  {sendMessageError ? (
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  ) : null}
                </div>

                {negociable ? 
                isProvider && !isTransactionCompleted ? 
                <NegociationModal
                offerMessage={offerMessage}
                handleOfferMessage={handleOfferMessage}
                onSendOffer={onSendOffer}
                currentTransaction={currentTransaction}
                currentListing={currentListing}
                offerSendOrAccepted={this.state.offerSendOrAccepted}
                handleOfferSendOrAccepted={this.handleOfferSendOrAccepted}
                />
                :  null
                :  null}

                <SecondaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <IconSendMessage />
                  <FormattedMessage id="SendMessageForm.sendMessage" />
                </SecondaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
