/* eslint-disable no-console */
import { CssBaseline } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Button, SecondaryButton, FieldTextInput } from '../../components';
import Modal from './Modal';
import css from './SendMessageForm.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const sharetribeSdk = require('sharetribe-flex-sdk');


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

const { UUID, LatLng } = sdkTypes;

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

export const NegociationModal = props => {

  const transactionId = props.currentTransaction.id.uuid;
  const listingId = props.currentListing.id.uuid;
  const wantedPrice = props.offerMessage;
  const handleOfferSendOrAccepted = props.handleOfferSendOrAccepted;
  const [isOpen, setOpen] = useState(false);
  const offerSend = props.offerSendOrAccepted;



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onSendOffer();
    handleOfferSendOrAccepted(true);
  };
  return (
    <div>
      <Modal
        {...props}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          console.log('Closing modal');
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ margin: '1rem' }}>

            <p>Escribe aquí el precio por hora que quieres proponer al cliente</p>
            <input 
                type="number"
                id="offerAmount"
                name="offerAmount"
                placeholder="€20"
                onChange={props.handleOfferMessage}
                value={props.offerMessage}
            />
            <br/>
            <Button onClick={handleClose}>Enviar oferta</Button>
        </div>
      </Modal>

      <div style={{ margin: '1rem' }}>
        <SecondaryButton onClick={handleOpen} className={offerSend ? css.offerAlredySendButton : css.sendOfferButton}>€ Enviar oferta</SecondaryButton>
      </div>
    </div>
  );
};

